import React from "react"

import { graphql } from "gatsby"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"
import { JesLayout } from "../../components/jes-layout"
import { Bold, StandardParagraph } from "../../components/blocks"

const ImpressumPage = ({ data }) => {
  const { t } = useI18next()

  return (
    <JesLayout title={t("Impressum")}>
      <h1>
        <Trans>Impressum</Trans>
      </h1>
      <StandardParagraph>
        <Bold>Information according to § 5 TMG</Bold>
      </StandardParagraph>
      <StandardParagraph>
        JES Kulturstiftung
        <br />
        Adolf-Münzer-Strasse 9<br />
        86919 Holzhausen / Ammersee
      </StandardParagraph>
      <StandardParagraph>Phone: +49 (0)8806 924115</StandardParagraph>
      <StandardParagraph>
        E-mail:{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="mailto:post@jes-kulturstiftung.de"
        >
          post@jes-kulturstiftung.de
        </a>
      </StandardParagraph>
      <StandardParagraph>
        Internet:{" "}
        <a
          style={{ fontSize: "inherit" }}
          href="http://www.jes-kulturstiftung.de"
        >
          www.jes-kulturstiftung.de
        </a>
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Authorised representatives of the Board of Directors</Bold>: Dr.
        Dr. Jochen Seifert and Erika Seifert
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Competent supervisory authority</Bold>
        <br />
        Government of Upper Bavaria
        <br />
        80534 Munich
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Tax authority</Bold>
        <br /> Kaufbeuren Tax Office (Tax No. 125/147/01502)
      </StandardParagraph>
      <StandardParagraph>
        Legal capacity recognised by the Government of Upper Bavaria on
        17.12.2020
      </StandardParagraph>
      <StandardParagraph>
        Non-profit status recognised on 18.02.2021
      </StandardParagraph>
      <StandardParagraph>
        <Bold>
          Responsible for editorial content according to § 18 Abs.2 MStV
        </Bold>
        :<br /> Dr. Dr. Jochen Seifert (address as above)
      </StandardParagraph>
      <StandardParagraph>
        © All rights reserved by JES Kulturstiftung
      </StandardParagraph>
      <StandardParagraph>
        <Bold>Picture sources</Bold>
        <ul style={{ fontSize: "inherit" }}>
          <li style={{ fontSize: "inherit" }}>Fundaziun Capauliana, Chur</li>
          <li style={{ fontSize: "inherit" }}>Manchester Art Gallery</li>
          <li style={{ fontSize: "inherit" }}>Museum Wiesbaden</li>
          <li style={{ fontSize: "inherit" }}>
            National Portrait Gallery, London
          </li>
          <li style={{ fontSize: "inherit" }}>Stadtmuseum München</li>
        </ul>
      </StandardParagraph>
    </JesLayout>
  )
}

export default ImpressumPage

export const query = graphql`
  query ImpressumENPageQuery($language: String!) {
    markdownRemark(frontmatter: { meta: { eq: "impressum" } }) {
      html
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
